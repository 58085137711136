const mutations ={
    set_asignarAulaAsiento(state, asignarAulaAsiento) {
        state.asignarAulaAsientoList = asignarAulaAsiento ? asignarAulaAsiento : null;
    },

    set_textFilter(state, searchText) {
        state.textFilter = searchText;
    },

    set_data_proceso_admision(state, data_proceso) {
        state.data_proceso_admision = data_proceso ? data_proceso : null;
    }

}

export default mutations;