import axios from "@//plugins//axios.js";
const actions ={

    async getListAsignarAulaAsiento({ commit }) {
        try {
            const { data } = await axios.get(`asignacion_postulantes/`);
            commit("set_asignarAulaAsiento", data);
            return true
        } catch (error) {
           return null
        }
    },
    async getListAsignarAulaAsientoExonerados({ commit }) {
        try {
            const { data } = await axios.get(`asignacion_postulantes/?tipo=exonerados`);
            commit("set_asignarAulaAsiento", data);
            return true
        } catch (error) {
            return null
        }
    },

    async insertarAsientos({ commit }, dataAsientos) {
        try {
            const {data} = await axios.post(`import_asiento/`, dataAsientos);
            commit("set_asignarAulaAsiento", null);
            return {status: true, data: data};
        } catch (error) {
            return null
        }
    },

    async get_getProcesoAdmision({ commit }) {
        try {
            const { data } = await axios.get(`proceso/`);
            commit("set_data_proceso_admision", data);
            return true
        } catch (e){
            return null
        }
    }
}

export default actions;