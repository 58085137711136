const getters = {
    get_asignarAulaAsiento: (state) => {
        return state.asignarAulaAsientoList;
    },

    get_filter_data: (state) => {
        if (state.textFilter === '') {
            return state.asignarAulaAsientoList;
        } else {
            return state.asignarAulaAsientoList.filter((item) => {
                return item.escuela.toLowerCase().includes(state.textFilter.toLowerCase()) ||
                    item.aula.toLowerCase().includes(state.textFilter.toLowerCase());
            });
        }
    },

    get_listEscuelas: (state) => {
        if(!state.asignarAulaAsientoList) return null

        let escuelas = []

        state.asignarAulaAsientoList.forEach((item) => {
            if (!escuelas.includes(item.escuela)) {
                escuelas.push(item.escuela)
            }
        });

        return escuelas
    },

    get_listAulas: (state) => {
        if(!state.asignarAulaAsientoList) return null

        let aulas = []
        state.asignarAulaAsientoList.forEach((item) => {
            if (!aulas.includes(item.aula)) {
                aulas.push(item.aula)
            }
        });

        return aulas
    },

    get_data_proceso_admision: (state) => {
        return state.data_proceso_admision;
    }

}

export default getters;