import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const home = () => import("@/views/Home.vue");

const routes = [
  {
    path: "",
    component: () => import("@//layout//MainLayout.vue"),
    children: [
      {
        path: "",
        redirect: "/home",
      },

      {
        path: "/home",
        name: "Inicio",
        component: home,
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/user",
        name: "Usuario",
        component: () => import("@/views/user/User.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/registro_pago",
        name: "Registro Pago",
        component: () => import("@/views/registro_pago/RegistroPago.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/postulante",
        name: "Postulantes",
        component: () => import("@/views/postulante/Postulante.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/registro_nota",
        name: "Registro Nota",
        component: () => import("@//views//registro_nota//RegistroNota.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/registro_examen",
        name: "Registro Examen",
        component: () => import("@//views//examen//List.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/recepcion_documento",
        name: "Recepcion Documento",
        component: () => import("@//views//recepcion_documento//List.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/vacante",
        name: "Vacantes",
        component: () => import("@//views//vacante//Vacante.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/importar_asiento",
        name: "Importar Asiento",
        component: () =>
          import("@//views//importar_asiento//ImportarAsiento.vue"),
        meta: {
          authRequired: true,
        },
      },

      {
        path: "/importar_puntaje",
        name: "Importar Puntaje",
        component: () => import("@/views/importar_puntaje/ImportarPuntaje.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/registro_incidente",
        name: "Registro Incidente",
        component: () =>
          import("@/views/registro_incidente/RegistroIncidente.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/upload_school",
        name: "upload_school",
        component: () =>
          import("@//views//upload_schools//UploadSchool.vue"),
        meta: {
            authRequired: true,
            },
      },
      {
        path: "/distribucion_aulas",
        name: "Distribución de Aulas",
        component: () =>
          import("@/views/distribucion_aulas/DistribucionAulas.vue"),
        meta: {
            authRequired: true,
        }
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/reporte/:id",
    name: "Reporte",
    component: () => import("@//views//Reportes//ReporteRegistro.vue"),
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/reporteDj/:id",
    name: "Reporte Declaracion Jurada",
    component: () =>
      import("@//views//Reportes//components//DeclaracionJuradaPrint.vue"),
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/reporteFp/:id",
    name: "Ficha de Postulante",
    component: () =>
      import("@//views//Reportes//components//FichaPostulantePrint.vue"),
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/reporte_exonerado/:id",
    name: "ReporteExonerado",
    component: () => import("@//views//Reportes//ReporteExonerado.vue"),
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/reporteConstancia/:id",
    name: "Constancia",
    component: () => import("@//views//Reportes//ConstanciaIngreso.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = JSON.parse(localStorage.getItem("token-oca-unap"));

  if (to.meta.authRequired && !token) {
    next("/login");
  } else {
    if (to.path == "/login" && token) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
